<template>
  <div class="main-content">
    <avue-form ref="form" :value="form" :option="formOption">
      <template slot="name">
        <el-input
          v-model="form.name"
          maxlength="20"
          placeholder="请输入"
          onkeyup="this.value=this.value"
          @blur="salaryDianChange($event, 'name')"
          type="text"
          clearable
          :disabled="true"
        />
      </template>
      <template slot="name6">
        <el-input
          v-model="form.name"
          maxlength="20"
          placeholder="请输入"
          onkeyup="this.value=this.value"
          @blur="salaryDianChange($event, 'name')"
          type="text"
          clearable
          :disabled="true"
        />
      </template>
      <template slot="name5">
        <el-input
          v-model="form.name"
          maxlength="20"
          placeholder="请输入"
          onkeyup="this.value=this.value"
          @blur="salaryDianChange($event, 'name')"
          type="text"
          clearable
          :disabled="true"
        /> </template
      ><template slot="name4">
        <el-input
          v-model="form.name"
          maxlength="20"
          placeholder="请输入"
          onkeyup="this.value=this.value"
          @blur="salaryDianChange($event, 'name')"
          type="text"
          clearable
          :disabled="true"
        /> </template
      ><template slot="name3">
        <el-input
          v-model="form.name3"
          maxlength="20"
          placeholder="请输入"
          onkeyup="this.value=this.value"
          @blur="salaryDianChange($event, 'name')"
          type="text"
          clearable
          :disabled="true"
        /> </template
      ><template slot="name2">
        <el-input
          v-model="form.name"
          maxlength="20"
          placeholder="请输入"
          onkeyup="this.value=this.value"
          @blur="salaryDianChange($event, 'name')"
          type="text"
          clearable
          :disabled="true"
        /> </template
      ><template slot="name1">
        <el-input
          v-model="form.name"
          maxlength="20"
          placeholder="请输入"
          onkeyup="this.value=this.value"
          @blur="salaryDianChange($event, 'name')"
          type="text"
          clearable
          :disabled="true"
        />
      </template>
      <!-- <template slot="menuForm">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" :loading="showLoading" @click="submit(form)"
          >确定</el-button
        >
      </template> -->
    </avue-form>
  </div>
</template>

<script>
import FormImgUpload from "@/components/formComponents/formImgUpload";
import { basicUpdate, basicView } from "@/api/system/basic";
import { enterChineseEnglish } from "@/utils/formRules";
import { userinfo } from "@/api";
export default {
  name: "createThree",
  components: {
    FormImgUpload,
  },
  data() {
    return {
      imgList: [
        ".png",
        ".jpg",
        ".jpeg",
        ".gif",
        ".svg",
        ".webp",
        ".bmp",
        ".tiff",
      ],
      videoList: [".mp4", ".webm", ".avi", ".mpeg"],
      fileList: [".pdf", ".doc", ".docx", "zip", "rar"],
      form: {
        imgType: "",
        imgSize: undefined,
      },
      showLoading: false,
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 150,
        column: [
          {
            label: "华为云账号",
            prop: "name",
            type: "text",
            maxlength: 20,
            placeholder: "请输入",
            span: 20,
          },
          {
            label: "密码",
            prop: "name",
            type: "text",
            maxlength: 20,
            placeholder: "请输入",
            span: 20,
          },
          {
            label: "管理员",
            prop: "name",
            type: "text",
            maxlength: 20,
            placeholder: "请输入",
            span: 20,
          },
          {
            label: "服务器配置",
            prop: "name",
            type: "text",
            maxlength: 20,
            placeholder: "请输入",
            span: 20,
          },
          {
            label: "数据库配置",
            prop: "name",
            type: "text",
            maxlength: 20,
            placeholder: "请输入",
            span: 20,
          },
          {
            label: "文件存储key",
            prop: "name",
            type: "text",
            maxlength: 20,
            placeholder: "请输入",
            span: 20,
          },
          {
            label: "短信key",
            prop: "name",
            type: "text",
            maxlength: 20,
            placeholder: "请输入",
            span: 20,
          },
        ],
      },
    };
  },
  created() {
    // this.onLoad();
  },
  methods: {
    //初始化
    onLoad() {
      this.showLoading = true;
      basicView().then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.form = {
            ...res.data,
            defaultAvatar: [res.data.defaultAvatar],
            defaultDeviceImage: [res.data.defaultDeviceImage],
            defaultGatewayImage: [res.data.defaultGatewayImage],
            defaultGroupImage: [res.data.defaultGroupImage],
            platformLogo: [res.data.platformLogo],
          };
        }
      });
    },
    //图片上传
    upload(e) {
      if (Array.isArray(e)) {
        this.form.defaultAvatar = [...e];
      } else {
        this.form.defaultAvatar = [...this.form.defaultAvatar, e];
      }
    },
    //默认设备图
    uploadDefaultDeviceImage(e) {
      if (Array.isArray(e)) {
        this.form.defaultDeviceImage = [...e];
      } else {
        this.form.defaultDeviceImage = [...this.form.defaultDeviceImage, e];
      }
    },
    //默认网关图
    uploadDefaultGatewayImage(e) {
      if (Array.isArray(e)) {
        this.form.defaultGatewayImage = [...e];
      } else {
        this.form.defaultGatewayImage = [...this.form.defaultGatewayImage, e];
      }
    },
    //默认分组图
    uploadDefaultGroupImage(e) {
      if (Array.isArray(e)) {
        this.form.defaultGroupImage = [...e];
      } else {
        this.form.defaultGroupImage = [...this.form.defaultGroupImage, e];
      }
    },
    //默认logo
    uploadDefaultPlatformLogo(e) {
      if (Array.isArray(e)) {
        this.form.platformLogo = [...e];
      } else {
        this.form.platformLogo = [...this.form.platformLogo, e];
      }
    },

    //手机号输入
    salaryDianChange(e, t) {
      const value = e.target.value;
      this.form[t] = value;
    },

    async setForm(form) {
      this.form = form;
    },
    //富文本框变化替换值
    editoChange(e) {
      this.form.details = e;
    },
    submit(form) {
      this.$refs.form.$refs.form.validate((valid, done) => {
        if (valid) {
          const res = {
            ...form,
            defaultAvatar: form.defaultAvatar[0],
            defaultDeviceImage: form.defaultDeviceImage[0],
            defaultGatewayImage: form.defaultGatewayImage[0],
            defaultGroupImage: form.defaultGroupImage[0],
            platformLogo: form.platformLogo[0],
          };
          this.showLoading = true;
          basicUpdate({ ...res })
            .then((res) => {
              if (res.code === 200) {
                this.$message.success("操作成功");
                this.dialogVisible = false;
                setTimeout(() => {
                  this.resetForm();
                }, 500);
                this.onLoad();
                this.getUserInfo();
                this.showLoading = false;
              } else {
                // this.$message.error(res.msg);
                this.showLoading = false;
                done();
              }
            })
            .catch(() => {
              this.showLoading = false;
            });
          // done()
        }
      });
    },
    getUserInfo() {
      userinfo()
        .then((res) => {
          if (res.code === 200) {
            this.headPortrait = res.data.headPortrait;
            this.fullName = res.data.fullName;
            this.$store.commit("setUserInfo", res.data);
          }
        })
        .catch((err) => {
          // this.$message.error(err.msg)
        });
    },
  },
};
</script>

<style scoped>
.main-content {
  padding-right: 60%;
}
</style>
